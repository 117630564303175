import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/service-2.svg",

    title: "Web Development",
    content:
      "Web developer specializing in responsive websites. Proficient in NextJs, React, NodeJs. Let's build something great together!.",
    color: "#161748",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/service-1.svg",
    title: "UI/Ux Design",
    content:
      "With 2 years of UI/UX experience, Proficient in Sketch and Figma, my designs blend creativity with functionality.",
    color: "#51d0de",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/software.svg",
    title: "Softwere Development",
    content:
      "I specialize in crafting robust and efficient solutions. Proficient in languages such as Java, and C#.     ",
    color: "#39a0ca",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Services" />
        <div className="row fix-spacing">
          {servicesData.map((service) => (
            <div className="col-md-4" key={service.id}>
              <Service service={service} />
            </div>
          ))}
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Looking for a custom job?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Click here
            </Link>{" "}
            to contact me! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
