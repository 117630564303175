import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Bachelor Of Information Technology",
    years: "2022 - Present",
    content: "UNIVERSITY OF MORATUWA (CODL).",
  },
  {
    id: 2,
    title: "Electrical Engineering Technology.",
    years: "2020 - 2023",
    content: "INSTITUTE OF TECHNOLOGY UNIVERSITY OF MORATUWA (NDT)",
  },
];

const experienceData = [
  {
    id: 1,
    title: "Electrical Engineer Trainee",
    years: "2023/09 - 2024/03",
    content: "Midas Safety Sri Lnaka",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
