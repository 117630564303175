import React from "react";
import TrackVisibility from "react-on-screen";
// import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "DinethDFonseka",
  avatarImage: "/images/about/1.jpg",
  content_1:
    "Hello, I’m a Dineth, web-developer based on Sri Lanka. I have rich experience in web site design & building and customization.",
  content_2:
    "I focus on creating applications that empower and simplify the lives of users, combining functionality with an intuitive user experience.",
};

const progressData = [
  {
    id: 1,
    title: "Javascript",
    percantage: 95,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Node Js",
    percantage: 90,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Next Js",
    percantage: 60,
    progressColor: "#6C6CE5",
  },
  {
    id: 2,
    title: "Typescript",
    percantage: 50,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Java",
    percantage: 70,
    progressColor: "#6C6CE5",
  },
];

// const counterData = [
//   {
//     id: 1,
//     title: "Projects completed",
//     count: 198,
//     icon: "icon-fire",
//   },
//   {
//     id: 2,
//     title: "Cup of coffee",
//     count: 5670,
//     icon: "icon-cup",
//   },
//   {
//     id: 3,
//     title: "Satisfied clients",
//     count: 427,
//     icon: "icon-people",
//   },
//   {
//     id: 4,
//     title: "Nominees winner",
//     count: 35,
//     icon: "icon-badge",
//   },
// ];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content_1}</p>
                  <p>{aboutContent.content_2}</p>
                  <div className="mt-3">
                    <a href="!#" className="btn btn-default">
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
